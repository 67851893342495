import React, { FunctionComponent } from 'react';
import { TechStackDomainListType } from '../../types';
import TechAppList from './techAppList';
import TechCatChartListing from './techCatChartListing';


const TechStackAppList: FunctionComponent<TechStackDomainListType> = ({ pageContext: { name, isDetails, byCategory, sortCategories } }) => {
  if (isDetails) {
    return (
      <TechCatChartListing queryData={sortCategories} />
    );
  }

  return (
    <TechAppList name={name} queryData={byCategory} />
  );
};

export default TechStackAppList;
