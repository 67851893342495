import React from 'react';
import { Translate } from "../translations";
import { PageHero } from "../page-hero";
import Section from "../section";
import TechStackAppList from './techStackList';
import './styles.scss';
import TechStackCategories from './techStackCategories';
import { TechStackDomainListType } from '../../types';
import Layout from '../layout/layout';

export default ({ pageContext }: TechStackDomainListType) => (
  <Layout>
    <PageHero
      title={<Translate name='TOP_USED_ALL_TECHNOLOGIES_TITLE' />}
      subtitle={<Translate name='TOP_USED_ALL_TECHNOLOGIES_SUBTITLE' />}
    />

    <Section>
      <div className="col-md-3">
        <TechStackCategories vertical active="top-apps" />
      </div>
      <div className="col-md-9">
        <TechStackAppList pageContext={pageContext} />
      </div>
    </Section>
  </Layout>
);
