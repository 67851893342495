import React from 'react';
import { Link } from 'gatsby';
import { IStringMapAny, IStringMap } from "../../types";
import PieChartMetrics from "../pie-chart-metrics";
import { ToolCard } from '../tool-card';
import { cleanURLSpace } from '../../helpers';

export default ({ queryData }: IStringMapAny) => {
  const colors: Array<string> = ['#f1c40f', '#1abc9c', '#2980b9', 'tomato', '#9b59b6', '#34495e', '#6F1E51', '#1B1464', '#12CBC4'];
  const categories: IStringMapAny = queryData ? queryData.filter((c: any) => c.percent > 0.1) : [];
  const catLabels = categories ? categories.slice(0, 3) : [];
  if (categories.length > 3) {
    let totalPercentSum = 0;
    catLabels.map((c: IStringMapAny) => totalPercentSum += c.percent)
    catLabels.push({
      name: "Others",
      percent: 100 - totalPercentSum,
    })
  }

  const pieChartlabels: Array<{
    text: string,
    value: string,
  }> = catLabels.map((category: IStringMap, index: number) => ({
    text: category.name,
    value: `${parseFloat(category.percent).toFixed(1)}%`,
    pointNum: category.percent,
    color: colors[index],
  }));

  const generateChartData = (labels: Array<{
    text: string,
    value: string,
  }>) => {
    /**
     * Here we can change colors how we want
     */

    if (!labels) return [];
    return labels.map((mock: any, index: number) => mock && ({
      name: `${mock.text} - ${mock.value}`,
      value: mock.pointNum,
      fill: colors[index] ? colors[index] : colors[0],
    }));
  };

  return (<>
    {pieChartlabels.length > 0 && (
      <PieChartMetrics
        title={categories.length}
        chartData={generateChartData(pieChartlabels)}
        labels={pieChartlabels}
      />
    )}
    <p>&nbsp;</p>
    {
      categories.map((category: IStringMapAny, index: number) => (
        <ToolCard
          key={category.name}
          image={<span className="techCategoryPercentIcon">{category.percent.toFixed(1)}%</span>}
          title={(
            <div>
              <Link to={`/most-popular-tech-stacks/${cleanURLSpace(category.name)}`}>
                #{index + 1}<h5>{category.name}</h5>
              </Link>
            </div>
          )}
          subTitle={''}
        />
      ))
    }
  </>)
};
