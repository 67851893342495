import React from 'react';
import dayjs from 'dayjs';
import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip} from 'recharts';
import utcPlugin from 'dayjs/plugin/utc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobe} from '@fortawesome/free-solid-svg-icons';
import {TechStackApplicationType, IStringMapAny} from '../../types';
import PieChartMetrics from '../pie-chart-metrics';
import {ToolCard} from '../tool-card';
import {cleanURL} from '../../helpers';

dayjs.extend(utcPlugin);

export default ({name, queryData}: IStringMapAny) => {
  const colors: Array<string> = [
    '#f1c40f',
    '#1abc9c',
    '#2980b9',
    'tomato',
    '#9b59b6',
    '#34495e',
    '#6F1E51',
    '#1B1464',
    '#12CBC4',
  ];

  let {apps, timestamp} = queryData;
  apps = apps || [];
  const appPercents: any = {};
  let sumPercent = 0;
  const isTopApps = name === 'top-apps';
  let categoryApps = apps;
  if (!isTopApps && categoryApps.length > 3) {
    categoryApps = apps.slice(0, 3);
  }

  categoryApps.map((app: TechStackApplicationType) => {
    if (typeof app.percent === 'undefined') return;
    appPercents[app.name] = app.percent;
    sumPercent += app.percent;
  });

  if (!isTopApps && apps.length > 3) {
    appPercents['Others'] = 100 - sumPercent;
  }

  const pieChartlabels: Array<{
    text: string;
    value: string;
  }> = Object.keys(appPercents).map((text: string, index) => ({
    text,
    value: `${appPercents[text].toFixed(1)}%`,
    pointNum: appPercents[text],
    color: colors[index],
  }));

  const generateChartData = (
    labels: Array<{
      text: string;
      value: string;
    }>,
  ) => {
    /**
     * Here we can change colors how we want
     */

    if (!labels) return [];
    return labels.map(
      (mock: any, index: number) =>
        mock && {
          name: isTopApps ? mock.text : `${mock.text} - ${mock.value}`,
          value: mock.pointNum,
          fill: colors[index] ? colors[index] : colors[0],
        },
    );
  };

  const renderTooltipContent = (tooltipValues: any) => {
    const {payload} = tooltipValues;
    return payload.map((entry: any, index: any) => (
      <div key={`item-${index}`} className="custom-tooltip">
        {`${entry.payload.name} - ${entry.payload.value.toFixed(1)}%`}
      </div>
    ));
  };

  const renderCustomAxisTick = (props: any) => {
    const {x, y, payload} = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="start" fill="#252525" transform="rotate(45)" className="f_size_12">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      {isTopApps ? (
        <div style={{width: '100%', height: 520, margin: 'auto'}}>
          <ResponsiveContainer>
            <BarChart data={generateChartData(pieChartlabels)} margin={{top: -60, right: 0, left: 0, bottom: 5}}>
              <Tooltip content={renderTooltipContent} cursor={false} />
              <XAxis dataKey="name" height={130} interval={0} textAnchor="end" tick={renderCustomAxisTick} />
              <Bar dataKey="value" barSize={25} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <PieChartMetrics title={apps.length} chartData={generateChartData(pieChartlabels)} labels={pieChartlabels} />
      )}
      <p>&nbsp;</p>
      {timestamp && (
        <small>
          Generated{' '}
          {dayjs(new Date(parseInt(timestamp)))
            .utc()
            .format('MMM DD, YYYY h:mm A (UTC)')}
        </small>
      )}
      <p>&nbsp;</p>
      {apps.map((app: TechStackApplicationType, index: number) => (
        <ToolCard
          key={app.name}
          image={
            app.icon ? (
              <img alt={app.name} src={`https://hexometer.com/tech-stack-icons/${encodeURIComponent(app.icon)}`} />
            ) : (
              <FontAwesomeIcon icon={faGlobe} size="2x" />
            )
          }
          title={
            app.website && !app.website.includes('ubercart.org') ? (
              <a
                href={app.website.includes('didomi') ? 'https://www.didomi.io/' : cleanURL(app.website)}
                target="_blank"
                rel="nofollow noopener"
              >
                #{index + 1}
                <h5>{app.name}</h5>
                {app.website && (
                  <i role="button" className="technology-site-url">
                    {app.website.replace('http://', '').replace('https://', '')}
                  </i>
                )}
              </a>
            ) : (
              <div>
                #{index + 1}
                <h5>{app.name}</h5>
                {app.website && (
                  <i role="button" className="technology-site-url">
                    {app.website.replace('http://', '').replace('https://', '')}
                  </i>
                )}
              </div>
            )
          }
          subTitle={app.description || ''}
        />
      ))}
    </>
  );
};
